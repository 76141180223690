import { BREAKPOINT_SM } from '../../abstracts/responsive';
import { getCookie, setCookie } from '../../abstracts/cookie';

const stickyBanner = () => ({
  showBanner: false,
  loaded: false,
  init() {
    if (window.innerWidth > BREAKPOINT_SM || parseInt(getCookie('vombfa')) === 1) {
      return; // show only on mobile && do not show if cookie is set
    }

    this.$dispatch('loaded');

    document.addEventListener('scroll', this.scroll.bind(this));
  },

  close() {
    this.$root.remove();
    document.removeEventListener('scroll', this.scroll);
    setCookie('vombfa', 1, 7);
  },

  scroll() {
    this.showBanner = window.scrollY > 0;

    if (!this.loaded && this.showBanner) {
      this.loaded = true;
      this.$dispatch('loaded');
    }
  },
});

export default stickyBanner;
